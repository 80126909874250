import React from 'react'
import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom' //, HashRouter


// Pour gérer les lazy import et ne pas avoir l'erreur suivante voir 
// A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator.
// To fix, updates that suspend should be wrapped with startTransition.
// https://stackoverflow.com/questions/72167518/a-component-suspended-while-responding-to-synchronous-input


// Tests
// import { TestReactWindow } from '../Tests/TestReactWindow'
// import TestCSS from '../Tests/TestCSS'

// Redux Strore
import { Provider } from 'react-redux'
import { store } from '../Redux/Store'

// Composants de Connexion, authentification
import Authentification from '../Navigation/Authentification/Authentification'
// import ErrorPage from "./Error";
import Connexion from '../Navigation/Connexion/Connexion.js'
import Logout from '../Navigation/Connexion/Logout'
import Denied from '../Navigation/Denied/Denied'
import CrashError from '../Navigation/CrashError/CrashError'



// Composants "trombinoscope"



// Composants que je ne peux pas mettre Lazy car sinon bug
// Si le fichier principal devient trop lourd il faudrait creuser pkoi c'est pas possible...
// import UserProfil from '../Users/Profil/ProfilUser'
// import MonProfil from '../Users/Profil/MonProfil'
// import MonSuivi from '../ListesActivites/MonSuivi/MonSuivi'
// import CommentsLastActs from '../Comments/CommentsLastActs'
// import Comments1Coureur from '../Comments/Comments1Coureur'


//--------------------------------------------------------------------------------------------------------------------------------------
// L'ordre des imports et des routes suit le Menu, histoire de s'y retrouver plus facilement

// Accueil
import Accueil from '../Accueil/Accueil'
import './MonComposant.js'
import MonComposant from './MonComposant.js'

// Password reset
// import ForgotPassword from '../Navigation/Connexion/ForgotPassword.js'
// import ChangePassword from '../Navigation/Connexion/ChangePassword.js'
const ForgotPassword = lazy(() => import('../Navigation/Connexion/ForgotPassword.js'))
const ChangePassword = lazy(() => import('../Navigation/Connexion/ChangePassword.js'))

// Routes Garmin
//import GarminAPIAuthorization from '../GarminAPI/GarminAPIAuthorization'
//import AuthorisationSuccess from '../GarminAPI/AuthorisationSuccess'
const GarminAPIAuthorization = lazy(() => import('../GarminAPI/garminAPIAuthorization.js'))
const AuthorisationSuccess = lazy(() => import('../GarminAPI/authorisationSuccess.js'))


// Routes non afiliées à un item du menu
// import ActDetails from '../Activite/Details'
const ActDetails = lazy(() => import('../Activite/Details'))

// Composants "trombinoscope"
// import {
//     PlanifCoureurs, ActivitesCoureurs, MaterielCoureurs, ZonesCoureurs, MassesCoureurs, CutsCoureurs, HistoriqueCoureurs, BilansCoureurs, SynchroGarminCoureurs, FeedbacksCoureurs
// } from '../Users/Trombinoscope/EffectifsCoureurs'
// https://stackoverflow.com/questions/61464342/how-to-lazy-load-multiple-components-at-a-same-time
const PlanifCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.PlanifCoureurs })))
const ActivitesCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.ActivitesCoureurs })))
const StatisticsTrainingCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.StatisticsTrainingCoureurs })))
const MaterielCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.MaterielCoureurs })))
const ZonesCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.ZonesCoureurs })))
const MassesCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.MassesCoureurs })))
const CutsCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.CutsCoureurs })))
const HistoriqueCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.HistoriqueCoureurs })))
const BilansCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.BilansCoureurs })))
const SynchroGarminCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.SynchroGarminCoureurs })))
const FeedbacksCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.FeedbacksCoureurs })))
const ExportFitCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.ExportFitCoureurs })))
const TestingCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.TestingCoureurs })))
const GlycemieCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.GlycemieCoureurs })))
const ComplementationCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.ComplementationCoureurs })))
const DebriefingsCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.DebriefingsCoureurs })))
const CalculsCoureurs = lazy(() => import('../Users/Trombinoscope/EffectifsCoureurs').then((module) => ({ default: module.CalculsCoureurs })))



// L'Equipes
// import EffectifSeason from '../Users/Trombinoscope/EffectifsSaisons'
// import UserProfil from '../Users/Profil/ProfilUser'
const EffectifsSaisons = lazy(() => import('../Users/Trombinoscope/EffectifsSaisons'))
const UserProfil = lazy(() => import('../Users/Profil/ProfilUser'))
const CalendrierTeam = lazy(() => import('../Calendrier/CalendrierTeam.js'))

// Mes Informations
// import MonProfil from '../Users/Profil/MonProfil'
// import Notifications from '../Users/Notifications/Notifications'
// import ChangePwd from '../Users/ChangePwd/ChangePwd'
// const MonProfil = lazy(() => import('../Users/Profil/MonProfil'))
const Notifications = lazy(() => import('../Users/Notifications/Notifications'))
const ChangePwd = lazy(() => import('../Users/ChangePwd/ChangePwd'))

// Mon Calendrier
// import MaPlanification from '../Planif/MaPlanification/MaPlanification'
const MaPlanification = lazy(() => import('../Planif/MaPlanification/MaPlanification'))

// Calendrier des Coureurs
// import Planif1Coureur from '../Planif/Planification/Planif1Coureur'
// import DualCalendar from '../Planif/DualCalendar/DualCalendar'
const Planif1Coureur = lazy(() => import('../Planif/Planification/Planif1Coureur'))
const DualCalendar = lazy(() => import('../Planif/DualCalendar/DualCalendar'))

// Mon Suivi d'Activités
// import MonSuivi from '../ListesActivites/MonSuivi/MonSuivi'
const MonSuivi = lazy(() => import('../ListesActivites/MonSuivi/MonSuivi'))

// Mon Suivi d'Activités
// import MonSuivi from '../ListesActivites/MonSuivi/MonSuivi'
const ListNews = lazy(() => import('../News/ListNews'))
const AddNews = lazy(() => import('../News/AddNews'))
const OneNews = lazy(() => import('../News/OneNews'))

// Suivi d'Activités
// import CommentsLastActs from '../Comments/CommentsLastActs'
// import Comments1Coureur from '../Comments/Comments1Coureur'
// import ActivitesGroup from '../ListesActivites/ActivitesGroup'
const SuiviMesCoureurs = lazy(() => import('../ListesActivites/MonSuivi/SuiviMesCoureurs'))
const CommentsLastActs = lazy(() => import('../Comments/CommentsLastActs'))
const Comments1Coureur = lazy(() => import('../Comments/Comments1Coureur'))
const ActivitesGroup = lazy(() => import('../ListesActivites/ActivitesGroup'))

// Debriefings
const Debriefs1Coureur = lazy(() => import('../Debriefings/Debriefs1Coureur'))
const CourseDebrief = lazy(() => import('../Debriefings/FicheDebriefCourse.js'))
const Debrief1Race = lazy(() => import('../Debriefings/Debrief1Race.js'))
const MesDebriefings = lazy(() => import('../Debriefings/MesDebriefings.js'))

//Module Recrutement
const RecrutementCumul = lazy(() => import('../Recrutement/AppRecrutementCumul'))


// Entrainement
// import AppCut1Coureur from '../Entrainement/Cut/AppCut1Coureur'
// import AppCut1Activity from '../Entrainement/Cut/AppCut1Activity'
// import Historique1Coureur from '../Entrainement/Historique/Historique1Coureur'
// import Bilans1Coureur from '../Entrainement/Bilans/Bilans1Coureur'
const AppCut1Coureur = lazy(() => import('../Entrainement/Cut/AppCut1Coureur'))
const AppCut1Activity = lazy(() => import('../Entrainement/Cut/AppCut1Activity'))
const Historique1Coureur = lazy(() => import('../Entrainement/Historique/Historique1Coureur'))
const Bilans1Coureur = lazy(() => import('../Entrainement/Bilans/Bilans1Coureur'))

//Stats entrainement

const StatsTraining1Coureur = lazy(() => import('../StatsTraining/StatsTraining_1Coureur'))
// Materiel
// import Materiel1Coureur from '../Materiel/Materiel1Coureur'
// import GestionMateriel from '../Materiel/GestionMateriel.js'
const Materiel1Coureur = lazy(() => import('../Materiel/Materiel1Coureur'))
const GestionMateriel = lazy(() => import('../Materiel/GestionMateriel'))
const RaceDebrief = lazy(() => import('../Materiel/RaceDebrief'))

//Glycemie
const Glycemie1Coureur = lazy(() => import('../Glycemie/Glycemie1Coureur'))
const Complementation1Coureur = lazy(() => import('../Glycemie/Complementation1Coureur'))


// Recrutement
const EffCalendarRecrues = lazy(() => import('../Recrutement/EffectifsRecrues').then((module) => ({ default: module.EffCalendarRecrues })))
const AddRecrue = lazy(() => import('../Recrutement/AddRecrue'))
const EffUpdateRecrues = lazy(() => import('../Recrutement/EffectifsRecrues').then((module) => ({ default: module.EffUpdateRecrues })))
const UpdateRecrue = lazy(() => import('../Recrutement/UpdateRecrue'))
const EffUploadFitRecrues = lazy(() => import('../Recrutement/EffectifsRecrues').then((module) => ({ default: module.EffUploadFitRecrues })))
const UploadFit1Coureur = lazy(() => import('../Recrutement/UploadFit1Coureur'))


//Testing
const Testing1Coureur = lazy(() => import('../Testing/Testing1Coureur'))

// Feedbacks
// import AppFeedbacks from '../Feedbacks/AppFeedbacks.js'
// import RequestFeedbacks1team from '../Feedbacks/RequestFeedback1team.js'
const AppFeedbacks = lazy(() => import('../Feedbacks/AppFeedbacks')) //
const RequestFeedbacks1team = lazy(() => import('../Feedbacks/RequestFeedback1team')) //


// Gestion des Coureurs
// import Zones1Coureur from '../Coureurs/Zones1Coureur'
const Zones1Coureur = lazy(() => import('../Coureurs/Zones1Coureur'))
const Masse1Coureur = lazy(() => import('../Coureurs/Masse1Coureur'))
const ExportFit1Coureur = lazy(() => import('../Coureurs/ExportFit1Coureur'))
const GarminSync1Coureur = lazy(() => import('../GarminAPI/GarminSync1Coureur'))
const Calculs1Coureur = lazy(() => import('../Coureurs/Calculs1Coureur'))


// Composants applications (ancien React)
const Home = lazy(() => import('../Apps/home/Home'))
const AppCut = lazy(() => import('../Apps/cut-app/components/AppCut'))
const AppBilans = lazy(() => import('../Apps/bilans/AppBilans'))
const AppHistory = lazy(() => import('../Apps/history/AppHistory'))
const Notice = lazy(() => import('../Apps/history/Notice'))
const AppTensions = lazy(() => import('../Apps/tensions/AppTensions'))
const AppPPR = lazy(() => import('../Apps/ppr/AppPPR'))
// const AppDataBasePerformance = lazy(() => import('../Apps/performance/AppDataBasePerformance'))
// const AppMaterial = lazy(() => import('../Apps/materiel/AppMaterial'))
const AppActivityRecap = lazy(() => import('../Apps/activityRecap/AppActivityRecap'))
const AppPVTTest = lazy(() => import('../Apps/cognitiveTests/AppPVTTest'))

// Composants Admin
const ConnexionAdmin = lazy(() => import('../Navigation/Connexion/ConnexionAdmin.js'))
const AddUser = lazy(() => import('../Users/Ajout/AddUser'))
const UpdateUser = lazy(() => import('../Users/Ajout/UpdateUser.js'))
const SetEffectifSeasons = lazy(() => import('../Users/Trombinoscope/SetEffectifSeasons.js'))
const EffectifUpdateAvatars = lazy(() => import('../Users/Trombinoscope/EffectifUpdateAvatars.js'))
const UpdateAvatar = lazy(() => import('../Users/Ajout/UpdateAvatar.js'))
const ModifyAccounts = lazy(() => import('../Users/Trombinoscope/ModifyAccounts.js'))
const DeleteAccounts = lazy(() => import('../Users/Trombinoscope/DeleteAccounts.js'))
const UsersTable = lazy(() => import('../Users/Gestion/UsersTable'))
const UsersDroits = lazy(() => import('../Users/Gestion/Droits'))
const CoureursCoachs = lazy(() => import('../Users/Gestion/CoureursCoachs'))
const Participation = lazy(() => import('../Events/Participation'))



// Anciennes routes
// Calendriers (droits AllUsers)
// import MonCalendrier from '../Calendrier/MonCalendrier'; // (StandBye)
// import Calendrier2Teams from '../Calendrier/Calendrier2Teams'; // (StandBye)
// import CalendrierCoureurs from '../Calendrier/CalendrierCoureurs'; // (StandBye)
// import Event from '../Events/Event'; // (StandBye)

// Gestion Effectif (en stand-bye avec Ipogée)
// import GestionEffectif from '../Events/GestionEffectif'; // (StandBye)
// import GestionEvent from '../Events/GestionEvent'; // (StandBye)


// Sécurité React : à lire et bien comprendre :
// https://medium.com/whatfix-techblog/reactjs-security-best-practices-6542b71a5577


function Fallback({ children }) {
    return (
        <Suspense fallback={<>Loading ...</>}>
            {children}
        </Suspense>
    )
}


export default function AllRoutes() {
    return (
        <Provider store={store}>
            {/* <HashRouter> */}
            <BrowserRouter>
                <Routes>
                    {/* <Route exact path="/" element={<Connexion />} errorElement={<ErrorPage />} /> */}
                    <Route exact path="/" element={<Accueil />} />
                    <Route exact path="/login" element={<Connexion />} />
                    <Route exact path="/forgot_password" element={<Fallback children={<ForgotPassword />} />} />
                    <Route exact path="/change_password/:emailHex/:keyChangePwd" element={<Fallback children={<ChangePassword />} />} />

                    {/* <Route exact path="/suivi_activites_team" element={<CommentsLastActs />} /> */}

                </Routes>

                <Routes>
                    <Route element={<Authentification />}>

                        {/* <Route exact path="/test_css2" element={<TestCSS />} /> */}
                        <Route exact path="/logout" element={<Logout />} />
                        <Route exact path="/denied" element={<Denied />} />
                        <Route exact path="/crash" element={<CrashError />} />

                        {/* Routes non affiliées à un item du menu */}
                        <Route path="/activite/:numero" element={<Fallback children={<ActDetails />} />} />
                        <Route path="/authentification_success" element={<Fallback children={<AuthorisationSuccess />} />} />

                        {/* Accueil */}
                        <Route exact path="/accueil" element={<Accueil />} />

                        {/* News */}
                        <Route exact path="/liste_news" element={<Fallback children={<ListNews />} />} />
                        <Route exact path="/add_news/:numero?" element={<Fallback children={<AddNews />} />} />
                        <Route exact path="/news/:numero" element={<Fallback children={<OneNews />} />} />

                        {/* L'Equipe */}
                        <Route exact path="/effectif" element={<Fallback children={<EffectifsSaisons link="user" />} />} />
                        <Route exact path="/user/:Id" element={<Fallback children={<UserProfil />} />} />
                        <Route exact path="/calendrier_equipe" element={<Fallback children={<CalendrierTeam />} />} />

                        {/* Mes Informations */}
                        {/* <Route exact path="/mon_profil" element={<Fallback children={<MonProfil />} />} /> */}
                        <Route exact path="/mon_profil" element={<MonComposant link='user' />} />
                        <Route exact path="/notifications" element={<Fallback children={<Notifications />} />} />
                        <Route exact path="/change_password" element={<Fallback children={<ChangePwd />} />} />
                        <Route exact path="/connect_garmin" element={<Fallback children={<GarminAPIAuthorization />} />} />
                        <Route exact path="/mon_suivi_poids" element={<MonComposant link='masse_corp' />} />
                        <Route exact path="/mes_zones" element={<MonComposant link='zones' />} />

                        {/* Mon Calendrier */}
                        <Route exact path="/mon_calendrier" element={<Fallback children={<MaPlanification />} />} />

                        {/* Calendrier des Coureurs */}
                        <Route exact path="/planif_coureurs" element={<Fallback children={<PlanifCoureurs />} />} />
                        <Route exact path="/calendrier_double/:coureur1?" element={<Fallback children={<DualCalendar />} />} />
                        <Route exact path="/planification/:Id/:keyWeek?/:numero?" element={<Fallback children={<Planif1Coureur />} />} />
                        {/* Stats entrainement des Coureurs */}

                        {/* Stats recrutement */}
                        <Route exact path="/stats_recrutement_travail_cumule" element={<Fallback children={<RecrutementCumul />} />} />

                        {/* Stats entrainement des Coureurs */}
                        <Route exact path="/stats_training_coureurs" element={<Fallback children={<StatisticsTrainingCoureurs />} />} />
                        <Route exact path="/stats_coureur/:Id" element={<Fallback children={<StatsTraining1Coureur />} />} />

                        {/* Feedbacks */}
                        <Route exact path="/feedbacks" element={<Fallback children={<FeedbacksCoureurs />} />} />
                        <Route exact path="/feedbacks/:Id" element={<Fallback children={<AppFeedbacks />} />} />
                        <Route exact path="/feedbacks/team" element={<Fallback children={<RequestFeedbacks1team />} />} />

                        {/* Mon Suivi d'Activités */}
                        <Route exact path="/mon_suivi" element={<Fallback children={<MonSuivi />} />} />

                        {/* Suivi d'Activités */}
                        <Route exact path="/activites_mes_coureurs" element={<Fallback children={<SuiviMesCoureurs />} />} />
                        <Route exact path="/suivi_activites_team/:team/:dateParam?" element={<Fallback children={<CommentsLastActs />} />} />
                        <Route exact path="/suivi_activites_entraineur/:coach/:dateParam?" element={<Fallback children={<CommentsLastActs />} />} />
                        <Route exact path="/activites_coureurs" element={<Fallback children={<ActivitesCoureurs />} />} />
                        <Route exact path="/suivi_activites_coureur/:Id/:dateParam?/:numero?" element={<Fallback children={<Comments1Coureur />} />} />
                        <Route exact path="/suivi_activites_course" element={<Fallback children={<ActivitesGroup />} />} />

                        {/* Debriefings Courses */}
                        <Route exact path="/debriefings_courses" element={<Fallback children={<DebriefingsCoureurs />} />} />
                        <Route exact path="/debriefs_coureur/:Id/:dateParam?/:numero?" element={<Fallback children={<Debriefs1Coureur />} />} />
                        <Route exact path="/debriefing_courses" element={<Fallback children={<CourseDebrief />} />} />
                        <Route exact path="/debrief_1_course/:numero" element={<Fallback children={<Debrief1Race />} />} />
                        <Route exact path="/mes_debriefings" element={<Fallback children={<MesDebriefings />} />} />


                        {/* Entrainement */}
                        <Route exact path="/cuts_coureurs" element={<Fallback children={<CutsCoureurs />} />} />
                        <Route exact path="/cuts/:Id" element={<Fallback children={<AppCut1Coureur />} />} />
                        <Route exact path="/cuts_activity/:numero" element={<Fallback children={<AppCut1Activity />} />} />
                        <Route exact path="/historique_coureurs" element={<Fallback children={<HistoriqueCoureurs />} />} />
                        <Route exact path="/historique/:Id" element={<Fallback children={<Historique1Coureur />} />} />
                        <Route exact path="/bilans_coureurs" element={<Fallback children={<BilansCoureurs />} />} />
                        <Route exact path="/bilans/:Id" element={<Fallback children={<Bilans1Coureur />} />} />

                        {/* Materiel */}
                        <Route exact path="/materiel_coureurs" element={<Fallback children={<MaterielCoureurs season={'2025'} notRedux={true} />} />} />
                        <Route exact path="/materiel/:Id" element={<Fallback children={<Materiel1Coureur />} />} />
                        <Route exact path="/gestion_materiel" element={<Fallback children={<GestionMateriel />} />} />
                        <Route exact path="/debriefing_materiel_courses" element={<Fallback children={<RaceDebrief />} />} />


                        {/* Suivi Médical */}
                        <Route exact path="/glycemie_coureurs" element={<Fallback children={<GlycemieCoureurs />} />} />
                        <Route exact path="/glycemie/:Id" element={<Fallback children={<Glycemie1Coureur />} />} />
                        <Route exact path="/complementation_coureurs" element={<Fallback children={<ComplementationCoureurs />} />} />
                        <Route exact path="/complementation/:Id" element={<Fallback children={<Complementation1Coureur />} />} />

                        {/* Recrutement */}
                        <Route exact path="/effectif_recrues/:year" element={<Fallback children={<EffCalendarRecrues />} />} />
                        <Route exact path="/add_recrue" element={<Fallback children={<AddRecrue />} />} />
                        <Route exact path="/eff_updates_recrues/:year" element={<Fallback children={<EffUpdateRecrues />} />} />
                        <Route exact path="/update_recrue/:Id" element={<Fallback children={<UpdateRecrue />} />} />
                        <Route exact path="/eff_upload_fit_recrues/:year" element={<Fallback children={<EffUploadFitRecrues />} />} />
                        <Route exact path="/upload_fit_coureur/:Id" element={<Fallback children={<UploadFit1Coureur />} />} />
                        
                        
                        {/* Testing */}
                        <Route exact path="/testing" element={<Fallback children={<TestingCoureurs season={'2025'} notRedux={true} />} />} />
                        <Route exact path="/testing/:Id" element={<Fallback children={<Testing1Coureur />} />} />

                        {/* Gestion des Coureurs */}
                        <Route exact path="/zones_coureurs" element={<Fallback children={<ZonesCoureurs />} />} />
                        <Route exact path="/masses_coureurs" element={<Fallback children={<MassesCoureurs />} />} />
                        <Route exact path="/zones/:Id" element={<Fallback children={<Zones1Coureur />} />} />
                        <Route exact path="/masse_corp/:Id" element={<Fallback children={<Masse1Coureur />} />} />
                        <Route exact path="/sync_garmins_coureurs" element={<Fallback children={<SynchroGarminCoureurs />} />} />
                        <Route exact path="/sync_garmin/:Id" element={<Fallback children={<GarminSync1Coureur />} />} />
                        <Route exact path="/export_fit_coureurs" element={<Fallback children={<ExportFitCoureurs />} />} />
                        <Route exact path="/export_fit/:Id" element={<Fallback children={<ExportFit1Coureur />} />} />
                        <Route exact path="/calculs_coureurs" element={<Fallback children={<CalculsCoureurs />} />} />
                        <Route exact path="/calculs/:Id" element={<Fallback children={<Calculs1Coureur />} />} />


                        {/* Applications */}
                        <Route exact path="/home" element={<Fallback children={<Home />} />} />
                        <Route exact path="/cuts" element={<Fallback children={<AppCut />} />} />
                        <Route exact path="/bilans" element={<Fallback children={<AppBilans />} />} />
                        <Route exact path="/history" element={<Fallback children={<AppHistory />} />} />
                        <Route exact path="/notice" element={<Fallback children={<Notice />} />} />
                        {/* <Route exact path="/glycemie" element={<Fallback children={<AppGlycemie />} />} /> */}
                        <Route exact path="/tensions" element={<Fallback children={<AppTensions />} />} />
                        <Route exact path="/ppr" element={<Fallback children={<AppPPR />} />} />
                        {/* <Route exact path="/performance" element={<Fallback children={<AppDataBasePerformance />} />} /> */}
                        {/* <Route exact path="/materiel" element={<Fallback children={<AppMaterial />} />} /> */}
                        <Route exact path="/activityRecap" element={<Fallback children={<AppActivityRecap />} />} />
                        <Route exact path="/AppPVTTest" element={<Fallback children={<AppPVTTest />} />} />

                        {/* Gestion Utilisateurs */}
                        <Route exact path="/ajout_utilisateur" element={<Fallback children={<AddUser />} />} />
                        <Route exact path="/update_utilisateurs"
                            element={<Fallback children={<EffectifsSaisons link="update_user" toutes={true} title="Mise à jour des utilisateurs" titleDoc="Modifications Utilisateurs" />} />} />
                        <Route exact path="/update_user/:Id" element={<Fallback children={<UpdateUser />} />} />
                        <Route exact path="/effectif_saisons/:season" element={<Fallback children={<SetEffectifSeasons />} />} />
                        <Route exact path="/effectif_update_avatar/:season" element={<Fallback children={<EffectifUpdateAvatars />} />} />
                        <Route exact path="/update_avatar/:season/:Id" element={<Fallback children={<UpdateAvatar />} />} />
                        <Route exact path="/modify_accounts" element={<Fallback children={<ModifyAccounts />} />} />
                        <Route exact path="/delete_accounts" element={<Fallback children={<DeleteAccounts />} />} />

                        <Route exact path="/gestion_users" element={<Fallback children={<UsersTable />} />} />
                        <Route exact path="/gestion_droits" element={<Fallback children={<UsersDroits />} />} />

                        {/* Admin */}
                        <Route exact path="/login_admin" element={<Fallback children={<ConnexionAdmin />} />} />
                        <Route exact path="/notifications_users"
                            element={<Fallback children={<EffectifsSaisons link="notifs_user" toutes={true} title="Notifications des utilisateurs" titleDoc="Notifications Utilisateurs" />} />} />
                        <Route exact path="/notifs_user/:Id" element={<Fallback children={<Notifications />} />} />
                        <Route exact path="/coureurs_entraineurs" element={<Fallback children={<CoureursCoachs />} />} />
                        <Route exact path="/participation" element={<Fallback children={<Participation />} />} />

                        {/* Composansts test */}
                        {/* <Route exact path="/test_window" element={<TestReactWindow />} /> */}

                        {/* Anciennes routes */}
                        {/* <Route path="/gestion_event/:numero" element={<GestionEvent />} />
                        <Route path="/gestion_effectif/:numero" element={<GestionEffectif />} /> */}

                    </Route>
                </Routes>
                {/* </HashRouter> */}
            </BrowserRouter>

        </Provider>

    )
}